
/*
 * Rob DePietro
 * 2024-09-19
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import Globals, {
  DEVELOPER,
} from '../../../../Globals';
import {
  getFormattedPhone,
  isLessThan18YearsAgo,
} from '../../../../utilities';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import CakeIcon from 'mdi-react/CakeIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import HighlightOffIcon from 'mdi-react/HighlightOffIcon';
import RestoreClockIcon from 'mdi-react/RestoreClockIcon';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import styles from '../styles';
import {
  useState,
} from 'react';
import utc from 'dayjs/plugin/utc';
import {
  verifyStudentEmail,
} from '../../../../actions';

dayjs.extend(localizedFormat);
dayjs.extend(utc);

const StudentInformationDetails = ({student}) => {
  const [_showMoreDetails, setShowMoreDetails] = useState(false);
  const admin = useSelector((state) => state.admin);
  const studentsPreviousRecords = useSelector((state) => state.studentsPreviousRecords);
  const PRETTY_DATE_FORMAT = 'MMM D, YYYY';
  const [_emailVerified, setEmailVerified] = useState(false);
  const [_openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();

  const _renderField = (label, content, action) => {
    return (
      <div style={styles.formFieldWrapper}>
        <h6 style={styles.formFieldLabel}>{label}</h6>
        <div style={styles.formFieldContent}>{content} {action}</div>
      </div>
    );
  };

  const _formatName = (firstName, middleName, lastName) => {
    return `${firstName} ${middleName}${middleName ? ' ' : ''}${lastName}`;
  };

  const _formatAddress = (streetAddress, addressLineTwo, city, state, zip, country) => {
    return (
      <span>
        {streetAddress}<br />
        {addressLineTwo && (
          <>
            {addressLineTwo}<br />
          </>
        )}
        {(!!city && !!state) &&
          <>{`${city}, ${state} ${zip}`}<br /></>
        }
        {country}
      </span>
    );
  };

  const _formatSchoolOfIntent = (name, city, state, program) => {
    return (
      <span>
        {name}<br />
        {city}
        {(city && state) && ', '}
        {state}
        {(city || state) && program && <br />}
        {program}
      </span>
    );
  };

  const _emailVerifyButton = () => {
    return (
      <div style={styles.detailsWrapper}>
        <Button
          variant='contained'
          color='primary'
          disabled={_emailVerified}
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          Verify Email
        </Button>
        <Dialog
          open={_openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Email Verification</DialogTitle>
          <DialogContent>
            <div>
              <p>{`Are you sure you verified ${student.email} as the correct email?`}</p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                dispatch(verifyStudentEmail(student.id));
                setEmailVerified(true);
                setOpenDialog(false);
              }}
            >
              Yes
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const _renderPreviousInfo = (previousRecords) => {
    return (
      <Tooltip
        title={
          previousRecords.map((previousRecord) => (
            <div>
              <p>Deprecated <strong>{previousRecord.inserted_at}</strong> by <strong>{previousRecord.changed_by}</strong>.</p>
              <p>Data:<strong>{previousRecord.data}</strong>.</p>
              <p>Notes: <strong>{previousRecord.notes}</strong></p>
            </div>
          ))
        }
      >
        <div>
          <RestoreClockIcon size={18} />
        </div>
      </Tooltip>
    );
  };

  const _getPreviousInfoByGroup = () => {
    const previousNames = [];
    const previousAddresses = [];
    const previousEmails = [];
    const previousPhoneNumbers = [];
    const previousSchoolOfIntent = [];
    const previousNotes = [];

    const snapshotStudent = {...student}; // this is used to track all student fields at each change (because previous records contain only the diff between changes...this will have all attributes)

    (studentsPreviousRecords[student.id] || []).forEach((previousRecord) => {
      Object.keys(previousRecord).forEach((attribute) => {
        if (previousRecord[attribute]) {
          snapshotStudent[attribute] = previousRecord[attribute];
        }
      });
      const currItem = {
        id: previousRecord.id,
        notes: previousRecord.notes,
        inserted_at: dayjs(previousRecord.inserted_at).format('L LT'),
        changed_by: previousRecord.changed_by,
      };
      if (previousRecord.first_name || previousRecord.middle_name || previousRecord.last_name) {
        previousNames.push({
          ...currItem,
          data: _formatName(snapshotStudent.first_name, snapshotStudent.middle_name, snapshotStudent.last_name),
        });
      }
      if (previousRecord.address_id) {
        previousAddresses.push({
          ...currItem,
          data: _formatAddress(
            previousRecord.address.line_1,
            previousRecord.address.line_2,
            previousRecord.address.city,
            previousRecord.address.state,
            previousRecord.address.zip_code,
            previousRecord.address.country,
          ),
        });
      }
      if (previousRecord.email) {
        previousEmails.push({
          ...currItem,
          data: previousRecord.email,
        });
      }
      if (previousRecord.phone) {
        previousPhoneNumbers.push({
          ...currItem,
          data: getFormattedPhone(previousRecord.phone),
        });
      }
      if (previousRecord.school_of_intent_id) {
        previousSchoolOfIntent.push({
          ...currItem,
          data: _formatSchoolOfIntent(
            previousRecord.school_of_intent.name,
            previousRecord.school_of_intent.city,
            previousRecord.school_of_intent.state,
            previousRecord.school_of_intent.program,
          ),
        });
      }
      if (previousRecord.notes) {
        previousNotes.push({
          ...currItem,
          data: previousRecord.notes,
        });
      }
    });

    return {
      previousNames,
      previousAddresses,
      previousEmails,
      previousPhoneNumbers,
      previousSchoolOfIntent,
      previousNotes,
    };
  };

  const _renderStudentInfo = () => {
    const {
      previousNames,
      previousAddresses,
      previousEmails,
      previousPhoneNumbers,
      previousSchoolOfIntent,
      previousNotes,
    } = _getPreviousInfoByGroup(student);

    return (
      <div style={styles.detailsWrapper}>
        <span style={styles.nameCakeWrapper}>
          {_renderField('Name', <span>{_formatName(student.first_name, student.middle_name, student.last_name)} {isLessThan18YearsAgo(student.birthday) && <CakeIcon style={styles.under18Icon} />}</span>, (previousNames.length === 0 ? null : _renderPreviousInfo(previousNames, 'names')))}
        </span>
        {_renderField('Email',
          <span>{student.email} {student.email_confirmed !== undefined && (student.email_confirmed ? <span title='Email verified'><CheckIcon color={Globals.colors.green} /></span> : <span title='Email not verified'><HighlightOffIcon color={Globals.colors.error} /></span>)}</span>, (previousEmails.length === 0 ? null : _renderPreviousInfo(previousEmails, 'emails')))}
        {student.email_confirmed !== undefined && !student.email_confirmed && _emailVerifyButton()}
        {_renderField('Phone', getFormattedPhone(student.phone), (previousPhoneNumbers.length === 0 ? null : _renderPreviousInfo(previousPhoneNumbers, 'phones')))}
        {_showMoreDetails &&
          <>
            {_renderField('Street address', _formatAddress(student.line_1, student.line_2, student.city, student.state, student.zip_code, student.country), (previousAddresses.length === 0 ? null : _renderPreviousInfo(previousAddresses, 'addresses')))}
            {_renderField('Birthday', dayjs(student.birthday).utc().format(PRETTY_DATE_FORMAT))}
            <Tooltip title={dayjs(student.created_at).format('LT')}>
              {_renderField('Account creation date', dayjs(student.created_at).format(PRETTY_DATE_FORMAT))}
            </Tooltip>
            <Tooltip title={dayjs(student.last_logged_date).format('LT')}>
              {_renderField('Most recent log in date', dayjs(student.last_logged_date).format(PRETTY_DATE_FORMAT))}{/* @TODO: add time too */}
            </Tooltip>
            {_renderField('School of intent', _formatSchoolOfIntent(student.school, student.school_city, student.school_state, student.program), previousSchoolOfIntent.length === 0 ? null : _renderPreviousInfo(previousSchoolOfIntent, 'School of Intent'))}
            {_renderField('Student ID', student.id)}
            {_renderField('Canvas ID', <a target='_blank' rel='noopener noreferrer' href={`${Globals.canvasUrl}/accounts/1/users/${student.canvas_id}`}>{student.canvas_id}</a>)}
            {_renderField('Notes', (previousNotes.length === 0 ? null : _renderPreviousInfo(previousNotes, 'notes')))}
            {!!admin.perms[DEVELOPER] && _renderField('Cognito ID', <a target='_blank' rel='noopener noreferrer' href={`https://console.aws.amazon.com/cognito/v2/idp/user-pools/us-east-1_t1KZiFDjT/user-management/users/details/${student.cognito_id}?region=us-east-1`}>{student.cognito_id}</a>)}
          </>
        }
        <div style={styles.detailsButtonWrapper}>
          <Button
            onClick={() => setShowMoreDetails(!_showMoreDetails)}
            color='secondary'
          >
            {_showMoreDetails ? 'Less' : 'More'}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>{_renderStudentInfo()}</div>
  );
};

export default StudentInformationDetails;
