/**
 * Nick Lewyn
 *© Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 * 2021-06-29
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  createNewCode,
} from '../../../actions';
import styles from './styles';
import {
  useDispatch,
} from 'react-redux';
import {
  useState,
} from 'react';

const ANY_COURSE = 'any';

const CreateDiscountDialog = ({
  open,
  closeDialog,
  displayCourseOptions,
  courseOptions,
  getDiscountCodeValue = false,
}) => {
  const [_courseForDiscount, setCourseForDiscount] = useState(ANY_COURSE);
  const [_category, setCategory] = useState('');
  const [_amountText, setAmountText] = useState('');
  const [_newDiscountCode, setNewDiscountCode] = useState(`${Math.random()}`.substring(2, 9));
  const [_notes, setNotes] = useState('');
  const [_discountType, setDiscountType] = useState('percent');
  const [_formErrors, setFormErrors] = useState(false);
  const [_inputErrors, setInputErrors] = useState(false);
  const [_reEnrollErrors, setReEnrollErrors] = useState(false);
  const dispatch = useDispatch();

  const _getValidFormErrors = () => {
    let isFormError = false;
    if (!_amountText || !_newDiscountCode || !_category) {
      isFormError = true;
    } else if (_discountType === 'percent' && _amountText === '100' && !Object.keys(_courseForDiscount).length) {
      isFormError = true;
    } else if (_discountType === 'dollars' && !Object.values(_courseForDiscount).length) {
      isFormError = true;
    }
    return isFormError;
  };

  const _getValidInputErrors = () => {
    let isInputError = false;
    if (_discountType === 'percent' && (_amountText > 100 || _amountText <= 0)) {
      isInputError = true;
    } else if (_discountType === 'dollars' && (_amountText > _courseForDiscount.price || _amountText <= 0)) {
      isInputError = true;
    }
    return isInputError;
  };

  const _getValidReEnrollErrors = () => {
    return _category === 're-enroll' && !Object.keys(_courseForDiscount).length;
  };

  return (
    <Dialog
      open={open}
      onClose={() => closeDialog()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='md'
    >
      <DialogTitle id='alert-dialog-title'>Create code</DialogTitle>
      <DialogContent style={styles.dialogContent}>
        <div style={styles.description}>
          <p style={styles.descriptionParagraph}>{'If the specified code already exists, you will be notified after pressing the submit button.'}</p>
          <p style={styles.descriptionParagraph}>{'Notes is an optional field for tracking the student\'s name and reason for discount.  For example "John Smith, military" or "Jane Smith, retaking"'}</p>
          <p style={styles.descriptionParagraph}>{'If you enter a value equal to the price of the course, it will automatically be changed to a percent discount code for 100%'}</p>
          {!!Object.keys(!!_courseForDiscount).length &&
            <p style={styles.descriptionParagraph}>{'Price for Current Selected Course: $'}{_courseForDiscount.price}</p>
          }
        </div>
        <Grid container style={styles.formRow}>
          <Grid>
            <TextField
              style={styles.textField}
              label='Amount'
              value={_amountText}
              onChange={(e) => setAmountText(e.target.value)}
              type='number'
              required
            />
          </Grid>
          <Grid>
            <FormControl>
              <InputLabel required id='type'>Discount type</InputLabel>
              <Select
                htmlFor='type'
                label='Discount Type'
                style={styles.selectField}
                value={_discountType}
                onChange={(e) => setDiscountType(e.target.value)}
              >
                <MenuItem value='percent'>percent off</MenuItem>
                <MenuItem value='dollars'>dollars off</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            {(displayCourseOptions || courseOptions) &&
              <Grid>
                <FormControl>
                  <InputLabel id='course' required={_discountType === 'dollars' || (_discountType === 'percent' && _amountText === '100') || _category === 're-enroll'}>Course</InputLabel>
                  <Select
                    label='Course'
                    htmlFor='course'
                    style={styles.selectField}
                    value={!!_courseForDiscount && Object.keys(_courseForDiscount).length ? _courseForDiscount : ANY_COURSE}
                    onChange={(e) => setCourseForDiscount(e.target.value === ANY_COURSE ? {} : e.target.value)}
                  >
                    {(_discountType !== 'dollars' && !(_discountType === 'percent' && _amountText === '100')) && <MenuItem key='any' value={ANY_COURSE}>Any Course</MenuItem>}
                    {courseOptions && Object.values(courseOptions).sort((a, b) => (a.code).localeCompare(b.code)).map((course) =>
                      <MenuItem key={course.id} value={course}>{`${course.code.toUpperCase()}: ${course.title}`}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid style={styles.formRow}>
          <Grid>
            <TextField
              style={styles.textField}
              label='Code'
              value={_newDiscountCode}
              onChange={(e) => setNewDiscountCode(e.target.value)}
              required
            />
          </Grid>
          <Grid>
            <FormControl>
              <InputLabel required id='category'>Category</InputLabel>
              <Select
                style={styles.categorySelectField}
                value={_category}
                label='Category'
                htmlFor='category'
                onChange={(e) => setCategory(e.target.value)}
              >
                <MenuItem value='military'>military</MenuItem>
                <MenuItem value='re-enroll'>re-enroll</MenuItem>
                <MenuItem value='other'>other</MenuItem>
                <MenuItem value='voucher'>voucher</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <TextField
              style={styles.textField}
              label='Notes'
              value={_notes}
              onChange={(e) => setNotes(e.target.value)}
              multiline
              maxRows='2'
            />
          </Grid>
        </Grid>
        {_formErrors && <span style={styles.errors}>Please fill out all required fields and try again</span>}
        {_inputErrors && <span style={styles.errors}>Please make sure amount is not above 100% and is a positive value</span>}
        {_reEnrollErrors && <span style={styles.errors}>Please make sure a course is selected for a re-enrollment</span>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (_getValidFormErrors()) {
              setFormErrors(true);
              return;
            }
            if (_getValidInputErrors()) {
              setInputErrors(true);
              return;
            }
            if (_getValidReEnrollErrors()) {
              setReEnrollErrors(true);
              return;
            }
            dispatch(createNewCode(
              (_discountType === 'dollars' && Number(_amountText) === _courseForDiscount.price) ? '100' : _amountText,
              (_discountType === 'dollars' && Number(_amountText) === _courseForDiscount.price) ? 'percent' : _discountType,
              !Object.keys(_courseForDiscount).length ? null : _courseForDiscount.id,
              _newDiscountCode,
              _notes,
              _category,
              () => {
                getDiscountCodeValue && getDiscountCodeValue(_newDiscountCode);
                closeDialog();
              },
            ));
          }}
          color='primary'
          variant='contained'
        >
          Create
        </Button>
        <Button
          onClick={() => closeDialog()}
          color='secondary'
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDiscountDialog;
