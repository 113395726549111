/*
 * Nick Lewyn
 * 2020-02-14
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import Globals, {
  TRANSCRIPT_REQUEST_STATUSES,
  nabcGlobalObject,
} from '../../Globals';
import {
  clearTranscriptRequests,
  fetchCompletedStudentElectronicTranscript,
  fetchGrades,
  fetchNABCDivisionElectronicTranscript,
  fetchSecureFileS3PreAuthLink,
  fetchStudentCourses,
  fetchStudentTranscript,
  fetchTranscriptRequestTypes,
  fetchTranscriptRequests,
  processElectronicStudentTranscripts,
  transcriptRequestExcel,
  updateStudentTranscriptRequest,
  uploadSecureFile,
} from '../../actions';
import {
  AdapterDayjs,
} from '@mui/x-date-pickers/AdapterDayjs';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import EditAddressDialog from './EditAddressDialog';
import Highlighter from 'react-highlight-words';
import ListAlt from '@mui/icons-material/ListAlt';
import PencilIcon from 'mdi-react/PencilIcon';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import provinces from 'provinces';
import styles from './styles';
import utc from 'dayjs/plugin/utc';
import {
  v4 as uuidv4,
} from 'uuid';

dayjs.extend(localizedFormat);
dayjs.extend(utc);

const PROVINCE_COUNTRY_US = 'US';
const US_STATES = provinces.filter((value) => value.country === PROVINCE_COUNTRY_US);
const TRANSCRIPT_REQUEST_DIVISION = nabcGlobalObject({
  portage: 'portage',
  nabc: 'nabc',
});

const TRANSCRIPT_TYPE_CHANGE_VALUES_LIST = [
  'official',
  'electronic',
];

const TRANSCRIPT_FIELDS_NOT_TO_SEARCH_ON = ['transcript_request_comments'];

const NABC_DIVISION = nabcGlobalObject({
  All: 0,
  Portage: 1,
  NABC: 2,
});

class Transcripts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNum: 0,
      rowsPerPage: 50,
      isProcessDialogOpen: false,
      selectedStudentTranscriptId: 0,
      transcriptRequestType: '',
      transcriptQty: 0,
      transcriptDialogComments: '',
      isElectronicTranscriptDialogOpen: false,
      selectedFilesForElectronicTranscripts: null,
      electronicTranscriptRequestOnCompleteEmailNotification: '',
      errorElectronicTranscriptRequestOnCompleteEmailNotification: false,
      searchText: '',
      displayActiveTranscriptsOnly: true,
      displayInvalidTranscriptsOnly: false,
      transcriptRequestStatus: TRANSCRIPT_REQUEST_STATUSES.Requested,
      completedSearchByValue: '',
      completedSearchByFilterText: '',
      displayElectronicTranscriptProcessingIcon: false,
      displayAddressEdit: false,
      lastDateDataRefreshed: dayjs().format('LLL'),
      sortKey: 'student_last_name',
      sortAsc: true,
      completedDateBegin: null,
      completedDateEnd: null,
      editSendToEmail: false,
      newSendToEmailValue: '',
      editTranscriptType: false,
      filterByTranscriptType: null,
    };
  }

  componentDidMount() {
    this.props.fetchTranscriptRequests(false);
    this.props.fetchTranscriptRequestTypes();
    this.setState({searchText: window.location.href.indexOf('?') !== -1 ? window.location.href.slice(window.location.href.indexOf('?') + 1) : ''});
  }

  _renderProcessDialog(studentId, studentName, nabcDivision) {
    return (
      <Dialog
        open={this.state.isProcessDialogOpen}
        onClose={() => this.setState({isProcessDialogOpen: false})}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Process Transcript Requests</DialogTitle>
        <DialogContent>
          <Box display='flex'>Student: {studentName}</Box>
          <Box display='flex'>Requested Type: {this.state.transcriptRequestType}</Box>
          <Box display='flex' mb={3}>Requested Qty: {this.state.transcriptQty}</Box>
          {this.props.studentCourses[studentId] && this.props.studentCourses[studentId].filter((course) => course.complete === 1 || course.withdrawn === 1).length > 0 && this.props.grades[studentId] &&
          <div style={styles.completedCourseTableDialogSection}>
            <Box display='flex'>Completed Courses</Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width='51%'>Course</TableCell>
                  <TableCell>Date Completed</TableCell>
                  <TableCell>Final Grade</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.studentCourses[studentId] && this.props.studentCourses[studentId].sort((a, b) => dayjs(b.date_concluded).toDate().getTime() - dayjs(a.date_concluded).toDate().getTime()).filter((course) => course.complete === 1 || course.withdrawn === 1).map((course) => {
                  return (
                    <React.Fragment key={course.id}>
                      <TableRow>
                        <TableCell>{course.name}</TableCell>
                        <TableCell>{course.date_concluded === null ? '-' : dayjs(course.date_concluded).format('L LT')}</TableCell>
                        <TableCell>{this.props.grades[studentId][course.id].final_grade}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          }
          {this.props.studentCourses[studentId] && this.props.studentCourses[studentId].filter((course) => course.complete === 0 && course.withdrawn === 0).length > 0 &&
          <div>
            <Box display='flex'>In-Progress Courses</Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width='51%'>Course</TableCell>
                  <TableCell>Date Started</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.studentCourses[studentId] && this.props.studentCourses[studentId].filter((course) => course.complete === 0 && course.withdrawn === 0).map((course) => {
                  return (
                    <React.Fragment key={course.id}>
                      <TableRow>
                        <TableCell>{course.name}</TableCell>
                        <TableCell>{course.date_started === null ? '-' : dayjs(course.date_started).format('L LT') }</TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          }
          {(nabcDivision === NABC_DIVISION.All || nabcDivision === NABC_DIVISION.NABC) &&
            <div>
              <Box display='flex' mt={3} mb={2}>NursingABC Transcripts</Box>
              <Grid container style={styles.buttonGroup}>
                <Grid><Button color='primary' variant='contained' onClick={() => this.props.fetchStudentTranscript(studentId, TRANSCRIPT_REQUEST_DIVISION.nabc, this.props.transcriptRequestTypes.official.id)}>Official</Button></Grid>
                <Grid><Button color='primary' variant='contained' onClick={() => this.props.fetchStudentTranscript(studentId, TRANSCRIPT_REQUEST_DIVISION.nabc, this.props.transcriptRequestTypes.unofficial.id)}>Unofficial</Button></Grid>
                <Grid><Button color='primary' variant='contained' onClick={() => this.props.fetchNABCDivisionElectronicTranscript(this.state.selectedStudentTranscriptId)}>Electronic</Button></Grid>
              </Grid>
            </div>
          }
          {(nabcDivision === NABC_DIVISION.All || nabcDivision === NABC_DIVISION.Portage) &&
            <div>
              <Box display='flex' mb={2} mt={3}>Portage Transcripts</Box>
              <Grid container style={styles.buttonGroup}>
                <Grid><Button color='primary' variant='contained' onClick={() => this.props.fetchStudentTranscript(studentId, TRANSCRIPT_REQUEST_DIVISION.portage, this.props.transcriptRequestTypes.unofficial.id)}>Unofficial</Button></Grid>
                <Grid><Button color='primary' variant='contained' disabled={!this.state.transcriptDialogComments} onClick={() => this.props.fetchCompletedStudentElectronicTranscript(studentId, this.state.transcriptDialogComments)}>Electronic</Button></Grid>
              </Grid>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={() => this.setState({isProcessDialogOpen: false})}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _renderProcessElectronicTranscriptDialog() {
    return (
      <Dialog
        open={this.state.isElectronicTranscriptDialogOpen}
        onClose={() => this.setState({isElectronicTranscriptDialogOpen: false, electronicTranscriptRequestOnCompleteEmailNotification: ''})}
      >
        <DialogTitle id='alert-dialog-title'>Process Electronic Requests</DialogTitle>
        <DialogContent>
          <Box display='flex' mb={3}>To process electronic transcripts, please select the zip file containing the transcript PDFs by selecting the choose file button. Next, enter an e-mail address for where you would like to receive notification for when the process has completed. Finally, click the process button. </Box>
          <Box display='flex'>
            <Button
              variant='contained'
              color='secondary'
              component='label'
            >
              <input
                type='file'
                name='file'
                onChange={(event) => this.onChangeHandler(event)}
                hidden={true}
              />
              Upload
            </Button>
            {this.state.selectedFilesForElectronicTranscripts && <div style={styles.uploadFileName}>{this.state.selectedFilesForElectronicTranscripts.name}</div>}
          </Box>
          {!!this.state.selectedFilesForElectronicTranscriptsError && <p style={styles.errorParagraph}>{this.state.selectedFilesForElectronicTranscriptsError}</p>}
          <Box display='flex'>
            <TextField
              style={{...styles.textField, width: '100%'}}
              label='Email'
              error={this.state.errorElectronicTranscriptRequestOnCompleteEmailNotification}
              placeholder='Email address to be notified when processing is complete'
              value={this.state.electronicTranscriptRequestOnCompleteEmailNotification}
              onChange={(e) => this.setState({electronicTranscriptRequestOnCompleteEmailNotification: e.target.value, errorElectronicTranscriptRequestOnCompleteEmailNotification: false})}
              margin='dense'
              required={true}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary' disabled={!this.state.selectedFilesForElectronicTranscripts || !!this.state.selectedFilesForElectronicTranscriptsError} variant='contained' onClick={() => {
              if (!this.state.electronicTranscriptRequestOnCompleteEmailNotification) {
                this.setState({errorElectronicTranscriptRequestOnCompleteEmailNotification: true});
                return;
              }
              this.props.fetchSecureFileS3PreAuthLink('transcripts/electronic_transcript_source', this.state.selectedFilesForElectronicTranscripts.name, this.state.selectedFilesForElectronicTranscripts.type, this.state.fileId, Globals.s3Buckets.privateAssets, () => {
                this.setState({displayElectronicTranscriptProcessingIcon: true});
                this.props.uploadSecureFile(this.state.selectedFilesForElectronicTranscripts, this.props.s3Actions[this.state.fileId].s3UploadPreAuthURL, 'application/zip', () => {
                  // make a call to backend to start processing the zip file with electronic transcripts
                  this.setState({displayElectronicTranscriptProcessingIcon: false});
                  this.props.processElectronicStudentTranscripts(this.state.electronicTranscriptRequestOnCompleteEmailNotification);
                  this.setState({isElectronicTranscriptDialogOpen: false});
                });
              });
            }}
          >
            Process
          </Button>
          {this.state.displayElectronicTranscriptProcessingIcon && <CircularProgress />}
          <Button color='secondary' onClick={() => this.setState({isElectronicTranscriptDialogOpen: false, electronicTranscriptRequestOnCompleteEmailNotification: ''})}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  onChangeHandler(event) {
    if (!event.target.files[0].name.endsWith('.zip')) {
      this.setState({selectedFilesForElectronicTranscriptsError: 'Invalid file type. Please upload a zip file.'});
    } else {
      this.setState({selectedFilesForElectronicTranscriptsError: ''});
    }
    this.setState({selectedFilesForElectronicTranscripts: new File([event.target.files[0]], `${dayjs().utc().format('YYYYMMDDHHmmss')}-SourceFile.zip`, {type: 'application/zip'}), fileId: uuidv4()});
  }

  _enableFilterSearchButton() {
    if (this.state.completedSearchByFilterText && this.state.completedSearchByValue && this.state.completedSearchByValue !== 'dateCompletedRange') {
      return false;
    }

    return !(this.state.completedSearchByValue === 'dateCompletedRange' && this.state.completedDateBegin && this.state.completedDateEnd);
  }

  _displayTablePageSettings(countLength) {
    return (
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component='div'
        count={countLength}
        rowsPerPage={this.state.rowsPerPage}
        page={this.state.pageNum}
        slotProps={{
          previousButton: {'aria-label': 'Previous Page'},
          nextButton: {'aria-label': 'Next Page'},
        }}
        onPageChange={(event, pageNum) => this.setState({pageNum})}
        onRowsPerPageChange={(event) => this.setState({rowsPerPage: event.target.value})}
      />
    );
  }

  _displayRecordStatus(status) {
    if (status === TRANSCRIPT_REQUEST_STATUSES.Invalid) {
      return styles.tableBorderInvalidStyle;
    } else if (status === TRANSCRIPT_REQUEST_STATUSES.Completed) {
      return styles.tableBorderCompleteStyle;
    } else {
      return styles.tableBorderUnCompleteStyle;
    }
  }

  _submitFilteredSearch() {
    if (this.state.completedSearchByValue === 'dateCompletedRange') {
      this.props.fetchTranscriptRequests(true, false, null, this.state.completedSearchByValue, `${this.state.completedDateBegin}:${this.state.completedDateEnd}`, this.state.filterByTranscriptType);
    } else {
      this.props.fetchTranscriptRequests(true, false, null, this.state.completedSearchByValue, this.state.completedSearchByFilterText);
    }
  }

  // Should this be in utilties.js?
  _formatDate(date) {
    return date.format('YYYY-MM-DD');
  }

  _resetStateVariables(transcriptStatus) {
    if (transcriptStatus === 'requested') {
      this.setState({
        transcriptRequestStatus: TRANSCRIPT_REQUEST_STATUSES.Requested,
        displayActiveTranscriptsOnly: true,
        completedSearchByValue: '',
        completedSearchByFilterText: '',
        displayInvalidTranscriptsOnly: false,
        completedDateBegin: null,
        completedDateEnd: null,
        filterByTranscriptType: null,
      });
    } else if (transcriptStatus === 'invalid') {
      this.setState({
        transcriptRequestStatus: TRANSCRIPT_REQUEST_STATUSES.Invalid,
        displayActiveTranscriptsOnly: false,
        completedSearchByValue: '',
        completedSearchByFilterText: '',
        displayInvalidTranscriptsOnly: true,
        completedDateBegin: null,
        completedDateEnd: null,
        filterByTranscriptType: null,
      });
    }
  }

  render() {
    const searchTerms = this.state.searchText.split(' ').map((text) => text.toLowerCase());
    const filteredStudents = this.props.transcriptRequests.filter((student) => {
      return searchTerms.every((searchTerm) => {
        return Object.keys(student).some((studentAttribute) => {
          if (!TRANSCRIPT_FIELDS_NOT_TO_SEARCH_ON.includes(studentAttribute)) {
            return `${student[studentAttribute]}`.toLowerCase().includes(searchTerm);
          }
          return false;
        });
      });
    });
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, filteredStudents.length - this.state.pageNum * this.state.rowsPerPage);
    return (
      <div style={styles.container}>
        <h3>Transcripts</h3>
        <p>Click one of the export transcripts button below to export in-process and received transcripts.</p>
        <Grid container>
          <Grid>
            <Button onClick={() => this.props.transcriptRequestExcel(TRANSCRIPT_REQUEST_DIVISION.portage)} color='primary' variant='contained'>
              Export Portage Transcripts
            </Button>
          </Grid>
        </Grid>
        {this.state.isElectronicTranscriptDialogOpen && this._renderProcessElectronicTranscriptDialog()}
        {/* @TODO: adding the box to place a spacer and commenting out process electronic transcripts button as we need to finalize the electronic transcript process */}
        <Box mb={3}></Box>
        <Box display='flex' mt={3} mb={3}><Button onClick={() => this.setState({isElectronicTranscriptDialogOpen: true})} color='primary' variant='contained'>Process electronic transcripts</Button></Box>
        <div style={styles.lastRefreshDate}><FormLabel style={{fontWeight: 'bold'}}>{`Last time data was retrieved: ${this.state.lastDateDataRefreshed}`}</FormLabel></div>
        <Paper>
          <Toolbar style={styles.filterArea}>
            <div style={styles.filtering}>
              <TextField
                style={styles.textField}
                label='Search'
                value={this.state.searchText}
                onChange={(e) => this.setState({searchText: e.target.value})}
                margin='dense'
              />
              <FormControlLabel
                style={{marginLeft: '8px'}}
                control={
                  <Switch
                    checked={this.state.displayActiveTranscriptsOnly}
                    onChange={(event) => {
                      if (event.target.checked && this.state.transcriptRequestStatus !== TRANSCRIPT_REQUEST_STATUSES.Requested) {
                        this.props.clearTranscriptRequests();
                        this.props.fetchTranscriptRequests(false, false);
                        this._resetStateVariables('requested');
                      }
                    }}
                  />
                }
                label='Active Transcripts'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.displayInvalidTranscriptsOnly}
                    onChange={(event) => {
                      if (event.target.checked && this.state.transcriptRequestStatus !== TRANSCRIPT_REQUEST_STATUSES.Invalid) {
                        this.props.clearTranscriptRequests();
                        this.props.fetchTranscriptRequests(false, true);
                        this._resetStateVariables('invalid');
                      }
                    }}
                  />
                }
                label='Invalid Transcripts'
              />
            </div>
            <div style={styles.completedFilterArea}>
              <FormControl style={{marginTop: '8px'}}>
                <InputLabel id='completed-filter-by'>Filter Completed Transcript By:</InputLabel>
                <Select
                  htmlFor='completed-filter-by'
                  label='Filter Completed Transcript By:'
                  style={styles.filterBySelectField}
                  value={this.state.completedSearchByValue}
                  onChange={(event) => this.setState({completedSearchByValue: event.target.value})}
                >
                  <MenuItem value='firstname'>First Name</MenuItem>
                  <MenuItem value='lastname'>Last Name</MenuItem>
                  <MenuItem value='username'>Username</MenuItem>
                  <MenuItem value='school'>School</MenuItem>
                  <MenuItem value='state'>State</MenuItem>
                  <MenuItem value='dateCompletedRange'>Date Completed Range</MenuItem>
                </Select>
              </FormControl>
              {this.state.completedSearchByValue === 'state' &&
                <FormControl style={{marginTop: '8px'}}>
                  <InputLabel id='state'>State</InputLabel>
                  <Select
                    style={styles.filterBySelectField}
                    htmlFor='state'
                    label='State'
                    value={this.state.completedSearchByFilterText}
                    onChange={(event) => this.setState({completedSearchByFilterText: event.target.value})}
                  >
                    {US_STATES.map((s) =>
                      <MenuItem value={s.short} key={s.short}>{s.short}</MenuItem>,
                    )}
                  </Select>
                </FormControl>
              }
              {this.state.completedSearchByValue !== 'dateCompletedRange' && this.state.completedSearchByValue !== 'state' &&
                <TextField
                  style={styles.searchTextField}
                  label=''
                  value={this.state.completedSearchByFilterText}
                  onChange={(e) => this.setState({completedSearchByFilterText: e.target.value})}
                  margin='dense'
                />
              }
              <div style={styles.dateRange}>
                {this.state.completedSearchByValue === 'dateCompletedRange' &&
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{width: '250px', marginTop: '8px'}}
                      value={dayjs(this.state.completedDateBegin)}
                      format='MM/DD/YYYY'
                      onChange={(e) => this.setState({completedDateBegin: this._formatDate(e)})}
                      style={styles.dateSearchPickerUtil}
                      label='Begin Date'
                      id='beginDate'
                    />
                    <DatePicker
                      sx={{width: '250px'}}
                      value={dayjs(this.state.completedDateEnd)}
                      format='MM/DD/YYYY'
                      onChange={(e) => this.setState({completedDateEnd: this._formatDate(e)})}
                      style={styles.datePicker}
                      label='End Date'
                      id='endDate'
                    />
                  </LocalizationProvider>
                }
                {this.state.completedSearchByValue === 'dateCompletedRange' &&
                  <FormControl>
                    <InputLabel style={styles.filterByTranscriptLabel} id='filterByTranscriptType'>Transcript Type (default All):</InputLabel>
                    <Select
                      style={styles.filterByTranscriptDDL}
                      id='filterByTranscriptType'
                      value={this.state.filterByTranscriptType}
                      onChange={(event) => {
                        this.setState({filterByTranscriptType: event.target.value});
                      }}
                    >
                      {[Object.keys(this.props.transcriptRequestTypes).map((k) =>
                        <MenuItem value={this.props.transcriptRequestTypes[k].id} key={this.props.transcriptRequestTypes[k].id}>{k}</MenuItem>),
                      ]}
                    </Select>
                  </FormControl>
                }
              </div>
              <Button
                color='primary'
                variant='contained'
                disabled={this._enableFilterSearchButton()}
                style={styles.filterSearchButton}
                onClick={() => {
                  this.setState({
                    displayActiveTranscriptsOnly: false,
                    displayInvalidTranscriptsOnly: false,
                    transcriptRequestStatus: TRANSCRIPT_REQUEST_STATUSES.Completed,
                  });
                  this.props.clearTranscriptRequests();
                  this._submitFilteredSearch();
                }}
              >
                Search
              </Button>
            </div>
          </Toolbar>
          {this._displayTablePageSettings(filteredStudents.length)}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='left'>
                  <TableSortLabel
                    active={this.state.sortKey === 'student_last_name'}
                    direction={this.state.sortAsc ? 'asc' : 'desc'}
                    onClick={() => {
                      const SORT_KEY = 'student_last_name';
                      this.setState((prevState) => {
                        return {
                          sortKey: SORT_KEY,
                          sortAsc: prevState.sortKey === SORT_KEY ? !prevState.sortAsc : true,
                        };
                      });
                    }}
                  >
                    Student
                  </TableSortLabel>
                </TableCell>
                <TableCell align='left'>
                  <TableSortLabel
                    active={this.state.sortKey === 'send_to_name'}
                    direction={this.state.sortAsc ? 'asc' : 'desc'}
                    onClick={() => {
                      const SORT_KEY = 'send_to_name';
                      this.setState((prevState) => {
                        return {
                          sortKey: SORT_KEY,
                          sortAsc: prevState.sortKey === SORT_KEY ? !prevState.sortAsc : true,
                        };
                      });
                    }}
                  >
                    Send To
                  </TableSortLabel>
                </TableCell>
                <TableCell align='left'>
                  <TableSortLabel
                    active={this.state.sortKey === 'request_created'}
                    direction={this.state.sortAsc ? 'asc' : 'desc'}
                    onClick={() => {
                      const SORT_KEY = 'request_created';
                      this.setState((prevState) => {
                        return {
                          sortKey: SORT_KEY,
                          sortAsc: prevState.sortKey === SORT_KEY ? !prevState.sortAsc : true,
                        };
                      });
                    }}
                  >
                    Requested
                  </TableSortLabel>
                </TableCell>
                <TableCell align='left'>Completed</TableCell>
                <TableCell align='left'></TableCell>
                <TableCell align='left'>Paid</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredStudents.sort((a, b) => {
                let valueOfA = a[this.state.sortKey];
                let valueOfB = b[this.state.sortKey];
                if (typeof valueOfA === 'string') {
                  valueOfA = valueOfA.toLowerCase().trim();
                  valueOfB = valueOfB.toLowerCase().trim();
                }
                if (valueOfA.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
                  // this is a date
                  valueOfA = new Date(valueOfA).getTime();
                  valueOfB = new Date(valueOfB).getTime();
                }
                if (this.state.sortAsc) {
                  return valueOfA > valueOfB ? 1 : -1;
                }
                return valueOfA < valueOfB ? 1 : -1;
              }).slice(this.state.pageNum * this.state.rowsPerPage, this.state.pageNum * this.state.rowsPerPage + this.state.rowsPerPage).map((request) => {
                return (
                  <React.Fragment key={request.transcript_request_id}>
                    <TableRow style={((request.request_status === TRANSCRIPT_REQUEST_STATUSES.Completed || request.request_status === TRANSCRIPT_REQUEST_STATUSES.Invalid) ? styles.completedTranscriptTableColor : null) || (request.transcript_request_comments ? styles.electronicNotAvailable : null)}>
                      <TableCell align='left' style={this._displayRecordStatus(request.request_status)}>
                        <Box display='flex' mb={1}>
                          <Highlighter
                            highlightStyle={styles.highlightedText}
                            searchWords={this.state.searchText.split(' ')}
                            autoEscape={true}
                            textToHighlight={`${request.student_first_name} ${request.student_middle_name} ${request.student_last_name}`}
                          />
                        </Box>
                        <Box display='flex'>Type:
                          <div style={{marginLeft: 4}}>
                            {(this.state.selectedStudentTranscriptId === request.transcript_request_id && this.state.editTranscriptType)
                              ? (
                                <Select
                                  labelId='transcriptTypeSelect'
                                  autoFocus={true}
                                  value={request.request_type}
                                  onChange={(e) => {
                                    this.setState({editTranscriptType: false});
                                    this.props.updateStudentTranscriptRequest(request.transcript_request_id, {transcript_type: e.target.value});
                                  }}
                                  onBlur={() => {
                                    this.setState({editTranscriptType: false});
                                  }}
                                >
                                  {[TRANSCRIPT_TYPE_CHANGE_VALUES_LIST.map((key) =>
                                    <MenuItem key={this.props.transcriptRequestTypes[key].id} value={this.props.transcriptRequestTypes[key].id}>{key[0].toUpperCase() + key.slice(1)}</MenuItem>),
                                  ]}
                                </Select>
                              ) : (
                                <Highlighter
                                  highlightStyle={styles.highlightedText}
                                  searchWords={this.state.searchText.split(' ')}
                                  style={request.request_type === this.props.transcriptRequestTypes.official.id || request.request_type === this.props.transcriptRequestTypes.electronic.id ? {cursor: 'pointer'} : null}
                                  autoEscape={true}
                                  textToHighlight={request.request_type_description}
                                  onDoubleClick={() => {
                                    if (request.request_type === this.props.transcriptRequestTypes.official.id || request.request_type === this.props.transcriptRequestTypes.electronic.id) {
                                      this.setState({
                                        editTranscriptType: true,
                                        selectedStudentTranscriptId: request.transcript_request_id,
                                      });
                                    }
                                  }}
                                />
                              )
                            }
                          </div>
                        </Box>
                        <Box display='flex'>Division:
                          <div style={{marginLeft: 4}}>
                            <Highlighter
                              highlightStyle={styles.highlightedText}
                              searchWords={this.state.searchText.split(' ')}
                              autoEscape={true}
                              textToHighlight={request.nabc_division_description}
                            />
                          </div>
                        </Box>
                        <Box display='flex'>Qty: {request.transcript_qty}</Box>
                        <Box display='flex'>Send To: {request.send_to}</Box>
                      </TableCell>
                      <TableCell align='left'>
                        <Box display='flex'>
                          <Highlighter
                            highlightStyle={styles.highlightedText}
                            searchWords={this.state.searchText.split(' ')}
                            autoEscape={true}
                            textToHighlight={request.send_to_name}
                          />
                          <button
                            style={styles.copyButton}
                            onClick={() => {
                              const cityStateZip = `${request.send_to_city}, ${request.send_to_state} ${request.send_to_zip}`;
                              const sendToAddress = `${request.send_to_address} ${request.send_to_address2}`;
                              const attn = `${request.send_to_contact ? `\nAttn: ${request.send_to_contact}` : ''}`;
                              const copyAddress = `${request.send_to_name}${attn}\n${sendToAddress}\n${cityStateZip}`;
                              navigator.clipboard.writeText(`${copyAddress}`);
                            }}
                          >
                            <ContentCopyIcon size={15} />
                          </button>
                          <button
                            style={styles.addressEditButton}
                            onClick={() => {
                              this.setState({displayAddressEdit: true, selectedStudentTranscriptId: request.transcript_request_id});
                            }}
                          >
                            <PencilIcon size={15} />
                          </button>
                          {!!request.grade_change_form_open &&
                            <div style={{marginLeft: 10}}>
                              <Tooltip title='Grade change form'>
                                <ListAlt />
                              </Tooltip>
                            </div>
                          }
                        </Box>
                        <Box display='flex'>
                          <Highlighter
                            highlightStyle={styles.highlightedText}
                            searchWords={this.state.searchText.split(' ')}
                            autoEscape={true}
                            textToHighlight={request.send_to_contact ? `Attn: ${request.send_to_contact}` : ''}
                          />
                        </Box>
                        <Box display='flex'>
                          <Highlighter
                            highlightStyle={styles.highlightedText}
                            searchWords={this.state.searchText.split(' ')}
                            autoEscape={true}
                            textToHighlight={`${request.send_to_address} ${request.send_to_address2}`}
                          />
                        </Box>
                        <Box display='flex'>
                          <Highlighter
                            highlightStyle={styles.highlightedText}
                            searchWords={this.state.searchText.split(' ')}
                            autoEscape={true}
                            textToHighlight={`${request.send_to_city}, ${request.send_to_state} ${request.send_to_zip}`}
                          />
                        </Box>
                        <Box display='flex'>
                          {(this.state.selectedStudentTranscriptId === request.transcript_request_id && this.state.editSendToEmail)
                            ? (
                              <TextField
                                style={styles.editEmailTextField}
                                value={this.state.newSendToEmailValue}
                                autoFocus={true}
                                onKeyUp={(e) => {
                                  if (['Escape', 'Enter'].includes(e.key)) {
                                    if (this.state.newSendToEmailValue !== request.send_to_email) {
                                      this.props.updateStudentTranscriptRequest(request.transcript_request_id, {school_email: this.state.newSendToEmailValue});
                                    }
                                    this.setState({editSendToEmail: false});
                                  }
                                }}
                                onChange={(e) => {this.setState({newSendToEmailValue: e.target.value});}}
                              />
                            )
                            : (
                              <Highlighter
                                highlightStyle={styles.highlightedText}
                                searchWords={this.state.searchText.split(' ')}
                                autoEscape={true}
                                textToHighlight={request.send_to_email}
                                onDoubleClick={() => {
                                  this.setState({
                                    editSendToEmail: true,
                                    newSendToEmailValue: request.send_to_email,
                                    selectedStudentTranscriptId: request.transcript_request_id,
                                  });
                                }}
                              />
                            )
                          }
                        </Box>
                      </TableCell>
                      <TableCell align='left'>
                        <Highlighter
                          highlightStyle={styles.highlightedText}
                          searchWords={this.state.searchText.split(' ')}
                          autoEscape={true}
                          textToHighlight={request.request_created}
                        />
                      </TableCell>
                      <TableCell align='left'>{(request.request_status === TRANSCRIPT_REQUEST_STATUSES.Requested) ? request.in_process : request.date_processed}</TableCell>
                      <TableCell align='left'>
                        {request.request_status === TRANSCRIPT_REQUEST_STATUSES.Requested &&
                          <Box display='flex' style={styles.processButton}>
                            <Link
                              component='button'
                              variant='body2'
                              onClick={() => {
                                this.setState({
                                  isProcessDialogOpen: true, selectedStudentTranscriptId: request.transcript_request_id, transcriptRequestType: request.request_type_description, transcriptQty: request.transcript_qty, transcriptDialogComments: request.transcript_request_comments,
                                });
                                this.props.fetchStudentCourses(request.student_id);
                                this.props.fetchGrades(request.student_id);
                              }}
                            >
                              Process
                            </Link>
                          </Box>
                        }
                        {this.state.selectedStudentTranscriptId === request.transcript_request_id && this.state.isProcessDialogOpen &&
                          this._renderProcessDialog(request.student_id, `${request.student_first_name} ${request.student_middle_name} ${request.student_last_name}`, request.nabc_division)
                        }
                        {this.state.selectedStudentTranscriptId === request.transcript_request_id && this.state.displayAddressEdit &&
                          <EditAddressDialog transcriptRequest={request} closeAddressEditDialog={() => {this.setState({displayAddressEdit: false});}} />
                        }
                        {request.request_status === TRANSCRIPT_REQUEST_STATUSES.Requested &&
                          <Link
                            style={styles.markInvalid}
                            component='button'
                            variant='body2'
                            onClick={() => {
                              this.props.updateStudentTranscriptRequest(request.transcript_request_id, {status: 4});
                            }}
                          >
                            Mark Invalid
                          </Link>
                        }
                        {request.request_status === TRANSCRIPT_REQUEST_STATUSES.Completed &&
                          <Link
                            component='button'
                            variant='body2'
                            onClick={() => {
                              this.props.updateStudentTranscriptRequest(request.transcript_request_id, {status: 1});
                            }}
                          >
                            Un-Complete
                          </Link>
                        }
                        {request.request_status === TRANSCRIPT_REQUEST_STATUSES.Completed && request.transcript_request_comments.startsWith('transcripts/') &&
                          <Box>
                            <Link
                              component='button'
                              variant='body2'
                              onClick={() => {
                                this.props.fetchCompletedStudentElectronicTranscript(request.student_id, request.transcript_request_comments);
                              }}
                            >
                              Download Electronic Transcript
                            </Link>
                          </Box>
                        }
                        {request.request_status === TRANSCRIPT_REQUEST_STATUSES.Invalid &&
                          <Link
                            component='button'
                            variant='body2'
                            onClick={() => {
                              this.props.updateStudentTranscriptRequest(request.transcript_request_id, {status: 1});
                            }}
                          >
                            Mark Valid
                          </Link>
                        }
                        {request.request_status === TRANSCRIPT_REQUEST_STATUSES.Requested && !!request.transcript_eligible && !request.grade_change_form_open &&
                          <Box display='flex'>
                            <Link
                              component='button'
                              variant='body2'
                              onClick={() => {
                                this.props.updateStudentTranscriptRequest(request.transcript_request_id, {status: 3});
                              }}
                            >
                              Complete
                            </Link>
                          </Box>
                        }
                        {!!request.grade_change_form_open &&
                          <Box display='flex'>
                            <Link
                              component='button'
                              variant='body2'
                              onClick={() => this.props.updateStudentTranscriptRequest(request.transcript_request_id, {grade_change_form_open: 0})}
                            >
                              Clear Grade Change Form
                            </Link>
                          </Box>
                        }
                      </TableCell>
                      <TableCell align='left'>{request.transcript_eligible ? <CheckIcon color={Globals.colors.green} size={25} /> : <CloseIcon style={{fill: Globals.colors.primary}} size={14} />}</TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{height: 49 * emptyRows}}>{/* @TODO: magic number */}
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {this._displayTablePageSettings(filteredStudents.length)}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    transcriptRequests: Object.values(state.transcriptRequests),
    transcriptRequestTypes: state.transcriptRequestTypes,
    studentCourses: state.studentCourses,
    studentTranscript: state.studentTranscript,
    s3Actions: state.s3Actions,
    admin: state.admin,
    grades: state.grades,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    clearTranscriptRequests,
    fetchCompletedStudentElectronicTranscript,
    fetchGrades,
    fetchNABCDivisionElectronicTranscript,
    fetchSecureFileS3PreAuthLink,
    fetchStudentCourses,
    fetchStudentTranscript,
    fetchTranscriptRequests,
    fetchTranscriptRequestTypes,
    transcriptRequestExcel,
    processElectronicStudentTranscripts,
    updateStudentTranscriptRequest,
    uploadSecureFile,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Transcripts);
